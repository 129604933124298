import { chunk, cloneDeep, has, set } from 'lodash-es'

export const getValueFromObjectByKey = (object: any, key: string) => {
    if (!object) {
        return
    }
    return key.split('.').reduce((o, i) => {
        return o[i]
    }, object)
}

export const removeUndefinedValuesFromObject = <O extends object>(object: O) => {
    return Object.fromEntries(
        Object.entries(object).filter(([, value]) => {
            return !(value === undefined || value === null)
        })
    )
}

export const mergeObjectsFromArray = <D extends object = object>(arrayOfObjects: D[]) => {
    return Object.assign({}, ...arrayOfObjects)
}

export const getByPath = (object: any, path: string): any => {
    return path.split('.').reduce((acc, value) => {
        return acc[value]
    }, object)
}

// More info: https://stackoverflow.com/questions/65392218/typescript-remove-keys-from-generic-object
export const removeKeysFromObject = <T extends object>(object: T) => {
    return <K extends keyof T>(...parts: Array<K>): Omit<T, K> => {
        return (Object.keys(object) as Array<keyof T>).reduce((acc, key) => {
            if (!parts.includes(key as any)) {
                acc[key] = object[key]
            }
            return acc
        }, {} as T)
    }
}

export const getNextIdInArray = <D extends object>(array: D[], idKey: keyof D): number => {
    return (
        array.reduce(
            (max, character) => {
                return character[idKey as string] > max ? character[idKey as string] : max
            },
            array?.[0]?.[idKey] || 1
        ) + 1
    )
}

export const chunkArray = <D = object>(array: D[], chunkSize: number = 10): [D[]] => {
    return chunk(array, chunkSize) as [D[]]
}

export const chunkWithPadding = <D = object>(array: D[], size: number): [D[]] => {
    const chunks = chunk(array, size)
    const lastChunk = chunks[chunks.length - 1]

    if (lastChunk.length < size) {
        const padding = new Array(size - lastChunk.length).fill({})
        chunks[chunks.length - 1] = lastChunk.concat(padding)
    }

    return chunks as [D[]]
}

export const updateValueInObjectByPath = <
    T extends object,
    K extends keyof DeepPartial<T> | string,
    V extends Extract<T, { [P in K]: any }>[K] | any
>(
    obj: T,
    key: K,
    value: V
): T => {
    return set(cloneDeep(obj), key, value)
}

export const updateGroupSelection = (
    checked: boolean,
    group: { id: string },
    child: { id: string },
    setSelected: Function
) => {
    setSelected((selected: { id: string; items: string[] }[]) => {
        const item = selected.find(selected => {
            return selected.id === group.id
        })
        if (item) {
            return selected.map(selected => {
                if (selected.id === group.id) {
                    return {
                        ...selected,
                        items: checked
                            ? [...selected.items, child.id]
                            : selected.items.filter(item => {
                                  return item !== child.id
                              })
                    }
                }
                return selected
            })
        }
        return checked ? [...selected, { id: group.id, items: [child.id] }] : selected
    })
}

export const undefinedValuesAsNullValues = (object: object) => {
    for (const prop in object) {
        if (object[prop] === undefined) {
            object[prop] = null
        } else if (typeof object[prop] === 'object' && object[prop] !== null) {
            undefinedValuesAsNullValues(object[prop])
        }
    }
    return object
}

export const hasObjectAttributes = (obj: object, attributes: string[]) => {
    for (const attr of attributes) {
        if (has(obj, attr)) {
            return true
        }
    }
    return false
}

export const removeEmptyStringValues = <T extends object>(obj: T) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== '')) as T
}
