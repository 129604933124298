import { CREATE_ORDER_ITEM_KEY } from '../../../hooks/api/order/item/useOrderItemCreate'
import { useContext, useMemo } from 'react'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import Loading from '../../base/loading/Loading'
import ThemeContext from '../../../contexts/ThemeContext'

const LoadingToast = () => {
    const { loading: isLoadingTheme, excludedQueryKey } = useContext(ThemeContext)
    const fetching = useIsFetching({
        predicate: query => !excludedQueryKey.includes(query.queryKey[0] as string)
    })

    const mutating = useIsMutating({
        predicate: ({ options: { mutationKey } = {} }) => {
            if (Array.isArray(mutationKey)) {
                return (
                    !mutationKey.includes(CREATE_ORDER_ITEM_KEY) && !excludedQueryKey.includes(mutationKey[0] as string)
                )
            }
            return (mutationKey as unknown as string) !== CREATE_ORDER_ITEM_KEY
        }
    })

    const isLoading = useMemo<boolean>(() => {
        return !!fetching || !!mutating || isLoadingTheme
    }, [fetching, mutating, isLoadingTheme])

    return (
        isLoading && (
            <div className='fixed w-8 h-8 bottom-3 right-3 z-50 bg-gray-470 rounded-lg flex items-center justify-center'>
                <Loading className='w-4 h-4 !text-white' />
            </div>
        )
    )
}

export default LoadingToast
