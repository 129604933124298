export const PersonIcon = () => {
    return (
        <svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z'
                stroke='#E5E5E5'
                strokeWidth='1.5'
            />
            <path
                d='M1 15C1 13 4 11 9 11C14 11 17 13 17 15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15Z'
                stroke='#E5E5E5'
                strokeWidth='1.5'
            />
        </svg>
    )
}
