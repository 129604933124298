import { IconProps } from './Icon.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React from 'react'

const HamburgerIcon = ({ fill, className }: IconProps) => {
    return (
        <svg
            className={buildClassesWithDefault('h-[24px] w-[20px]', className)}
            width='22'
            height='14'
            viewBox='0 0 22 14'
            fill={fill}
            xmlns='http://www.w3.org/2000/svg'
        >
            <line y1='1.4' x2='22' y2='1.4' stroke='#4B5563' strokeWidth='1.2' />
            <line y1='7.4' x2='22' y2='7.4' stroke='#4B5563' strokeWidth='1.2' />
            <line y1='13.4' x2='12' y2='13.4' stroke='#4B5563' strokeWidth='1.2' />
        </svg>
    )
}

export default HamburgerIcon
