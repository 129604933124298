import { ToastOptions, toast } from 'react-toastify'

const useToast = () => {
    const defaultOptions: ToastOptions = {
        closeOnClick: true,
        autoClose: 5000,
        hideProgressBar: true
    }

    return {
        success: (content, options = {}) => toast.success(content, { ...defaultOptions, ...options }),
        error: (content, options = {}) => toast.error(content, { ...defaultOptions, ...options }),
        info: (content, options = {}) => toast.info(content, { ...defaultOptions, ...options }),
        warning: (content, options = {}) => toast.warning(content, { ...defaultOptions, ...options })
    }
}

export default useToast
