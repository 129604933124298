import { Permission } from '../hoc/withPermissionLogic'
import { PropsWithChildren, createContext, useContext } from 'react'

export type PermissionContextType = {
    permission: Permission
}

export type PermissionProviderProps = Pick<PermissionContextType, 'permission'>

const PermissionContext = createContext<PermissionContextType>({
    permission: {
        types: []
    }
})

export const PermissionProvider = ({ permission, children }: PropsWithChildren<PermissionProviderProps>) => {
    return <PermissionContext.Provider value={{ permission }}>{children}</PermissionContext.Provider>
}

export const usePermissions = (): PermissionContextType => {
    const context = useContext(PermissionContext)
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionProvider')
    }
    return context
}
