import moment from 'moment'

export const unixToDate = (unix: number) => {
    return moment.unix(unix).toDate()
}

export const formatDate = (date: string | Date, format: string = 'DD.MM.YYYY') => {
    return moment(date).format(format)
}

export const formatDateToIso = (
    date: string | Date,
    type: 'date' | 'datetime' = 'datetime',
    withoutSeconds: boolean = false
) => {
    const timeFormat = withoutSeconds ? ' HH:mm' : ' HH:mm:ss'
    return moment(date).format(`YYYY-MM-DD${type === 'datetime' ? timeFormat : ''}`)
}

export const stringToDate = (date: string) => {
    return moment(date).toDate()
}
