import { HTMLAttributes, PropsWithChildren } from 'react'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'

export type WrapperType = 'table' | 'dashboard' | 'default'

type WrapperContentProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> & {
    type?: WrapperType
}

const WrapperContent = ({ children, className, type = 'default' }: PropsWithChildren<WrapperContentProps>) => {
    return (
        <div
            className={buildClassesWithDefault(
                'flex flex-col gap-6 justify-center align-middle w-full mx-auto [&_form]:flex-1',
                type === 'default' ? 'w-full max-w-4xl' : '',
                className
            )}
        >
            {children}
        </div>
    )
}

export default WrapperContent
